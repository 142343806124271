
import { defineComponent, ref, reactive } from "vue";
import CloudFun, { Condition, Operator } from "@cloudfun/core";
import { helper as $h } from "@/utils/helper";
import { VxeFormProps } from "vxe-table";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
// import FileUploader from "@/cloudfun/components/FileUploader.vue";
// import AddressSelectBox from "@/components/address-select-box/Main.vue"

export default defineComponent({
  components: {
    Grid,
    // FileUploader,
    // AddressSelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    const gridOptions: GridOptions = {
      title: "會員資訊",
      canCreate: false,
      canDelete: false,
      canUpdate: false,
      canRead: false,
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: true,
      },
      exportConfig: {
        filename: "會員清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: printColumns
      },
      columns: [
        // {
        //   field: "Number",
        //   title: "會員編號",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true
        // },
        {
          field: "Person.Name",
          title: "會員名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "MobilePhone",
          title: "手機",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        // {
        //   field: "Status",
        //   title: "狀態",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   formatter: ({ cellValue }) => model ? Object.values(model.enums.MemberStatus).find(e => e.Value === cellValue)?.Name : undefined
        // },
        {
          title: "紀錄",
          width: "220",
          slots: { default: "grid-record" }
        },
      ],
      promises: {
        query: model
          ? (params) => new Promise(resolve => {
            model.dispatch('member/query', params).then(res => {
              res.data.forEach((e: any) => { e.MobilePhone = CloudFun.utils.formatPhone(e.MobilePhone) })
              resolve(res);
            })
          })
          : undefined,
        queryAll: model ? () => new Promise(resolve => {
            model.dispatch('member/query').then(res => {
              res.forEach((e: any) => { e.MobilePhone = CloudFun.utils.formatPhone(e.MobilePhone) })
              resolve(res);
            })
          }) : undefined,
        save: model
          ? (params) => model.dispatch('member/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 110,
      titleAlign: "right",
      items: [
        // { field: "Photo.Uri", title: "頭像", span: 24, slots: { default: "column-photo" } },
        { field: "Person.Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "姓名" } } },
        { field: "Status", title: "狀態", span: 12, itemRender: { name: "$select", props: { placeholder: "狀態" }, options: model ? Object.values(model.enums.MemberStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        // { field: "Account", title: "帳號", span: 12, itemRender: { name: "$input", props: { placeholder: "帳號", disabled: true } } },
        // { field: "Email", title: "Email", span: 24, itemRender: { name: "$input", props: { placeholder: "Email" } } },
        // { field: "Password", title: "密碼", span: 12, itemRender: { name: "$input", props: { type: "password", placeholder: "密碼", autocomplete: "new-password" } } },
        { field: "Person.MobilePhone", title: "手機", span: 12, itemRender: { name: "$input", props: { placeholder: "手機" } } },
        // { field: "Person.Nickname", title: "暱稱", span: 12, itemRender: { name: "$input", props: { placeholder: "名稱" } } },
        // { field: "Person.Sex", title: "性別", span: 12, itemRender: { name: "$select", props: { placeholder: "性別" }, options: model ? Object.values(model.enums.Sex).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        // { field: "Person.BirthDate", title: "生日", span: 12, itemRender: { name: "$input", props: { placeholder: "生日", type: "date" } } },
        // { field: "Person.Address", title: "地址", span: 24, slots: { default: "column-address" } },
        { field: "Remark", title: "備註", span: 24, itemRender: { name: "$textarea" } },
      ],
      rules: {
        // "Person.Name": [{ required: true }],
        "Person.MobilePhone": [{
          required: true,
          validator: (params) => {
            if (!params.itemValue || CloudFun.utils.validator.validatePhoneNumber(params.itemValue)) return new Error("手機格式錯誤");
          }
        }]
      }
    };

    const orderGrid = ref<any>();
    const orderModal = reactive({
      visible: false,
      memberId: "",
      gridOptions: {
        canCreate: false,
        canUpdate: false,
        canDelete: false,
        canRead: true,
        multiselect: false,
        toolbarConfig: {
          custom: false,
          refresh: true,
        },
        columns: [
          {
            field: "Time",
            title: "消費時間",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true,
            formatter: ({ cellValue }) => $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
          },
          {
            field: "Status",
            title: "訂單狀態",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true,
            formatter: ({ cellValue }) => model ? Object.values(model.enums.OrderStatus).find(e => e.Value === cellValue)?.Name : undefined
          },
          {
            field: "Amount",
            title: "金額",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true
          }
        ],
        promises: {
          query: model
            ? (params) => {
              params.condition!.and("MemberId", Operator.Equal, orderModal.memberId);
              return model.dispatch('order/query', params)
            }
            : undefined
        },
        modalConfig: { width: "80%", height: "80%" },
      } as GridOptions,
      show(id: string) {
        orderModal.visible = true;
        orderModal.memberId = id;
        if (orderGrid.value) orderGrid.value.refresh();
      },
      edit(row: any, callback: any) {
        orderDetailModal.orderId = row.Id;
        if (orderDetailGrid.value) orderDetailGrid.value.refresh();
        callback();
      }
    })

    const orderDetailGrid = ref<any>();
    const orderDetailModal = reactive({
      visible: false,
      orderId: "",
      gridOptions: {
        canCreate: false,
        canUpdate: false,
        canDelete: false,
        canRead: false,
        multiselect: false,
        toolbarConfig: {
          custom: false,
          refresh: true,
        },
        columns: [
          {
            field: "ProductName",
            title: "商品",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true
          },
          {
            field: "Price",
            title: "單價",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true
          },
          {
            field: "Quantity",
            title: "數量/時數",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true
          },
          {
            field: "Amount",
            title: "金額",
            formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue),
          }
        ],
        promises: {
          query: model
            ? (params) => {
              params.condition!.and("OrderId", Operator.Equal, orderDetailModal.orderId);
              return new Promise(resolve => {
                model.dispatch('orderItem/query', params).then(async res => {
                  const products = await model.dispatch('product/query', { condition: new Condition("Id", Operator.In, res.data.map((e: any) => e.ProductId)) });
                  res.data.forEach((e: any) => {
                    e.ProductName = products.find((p: any) => p.Id === e.ProductId).Name;
                  });
                  resolve(res);
                })
              });
            }
            : undefined
        },
        modalConfig: { width: "80%", height: "80%" },
      } as GridOptions
    })

    return {
      grid,
      gridOptions,
      formOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/Files`,
      orderGrid,
      orderModal,
      orderDetailGrid,
      orderDetailModal
    };
  }
});
